import { default as _91slug_938KnEvpZ3djMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/[slug].vue?macro=true";
import { default as _404adLVrwfdvXMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/404.vue?macro=true";
import { default as aboutpMm9Zf3LjaMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/about.vue?macro=true";
import { default as contactrTbtSETogYMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/contact.vue?macro=true";
import { default as index8PAxBsnL3tMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/index.vue?macro=true";
import { default as kaleidoscope0dFt8Vp3xZMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/kaleidoscope.vue?macro=true";
import { default as data_45privacy2lVyjEUU8wMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/data-privacy.vue?macro=true";
import { default as imprintYEiBxZiDfUMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/imprint.vue?macro=true";
import { default as terms_45and_45servicesZlg7Flo9s3Meta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/terms-and-services.vue?macro=true";
import { default as sustainabilityfETX4dphbUMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/sustainability.vue?macro=true";
import { default as urban_45brand_45spaceGGRtkMVXoKMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/urban-brand-space.vue?macro=true";
export default [
  {
    name: "slug___de___default",
    path: "/:slug()",
    meta: _91slug_938KnEvpZ3djMeta || {},
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug()",
    meta: _91slug_938KnEvpZ3djMeta || {},
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    meta: _91slug_938KnEvpZ3djMeta || {},
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "404___de___default",
    path: "/404",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "404___de",
    path: "/de/404",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "404___en",
    path: "/en/404",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "about___de___default",
    path: "/about",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/about",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "contact___de___default",
    path: "/contact",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index___de___default",
    path: "/",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kaleidoscope___de___default",
    path: "/kaleidoscope",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/kaleidoscope.vue").then(m => m.default || m)
  },
  {
    name: "kaleidoscope___de",
    path: "/de/kaleidoscope",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/kaleidoscope.vue").then(m => m.default || m)
  },
  {
    name: "kaleidoscope___en",
    path: "/en/kaleidoscope",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/kaleidoscope.vue").then(m => m.default || m)
  },
  {
    name: "services-data-privacy___de___default",
    path: "/services/data-privacy",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: "services-data-privacy___de",
    path: "/de/services/data-privacy",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: "services-data-privacy___en",
    path: "/en/services/data-privacy",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: "services-imprint___de___default",
    path: "/services/imprint",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: "services-imprint___de",
    path: "/de/services/imprint",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: "services-imprint___en",
    path: "/en/services/imprint",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: "services-terms-and-services___de___default",
    path: "/services/terms-and-services",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/terms-and-services.vue").then(m => m.default || m)
  },
  {
    name: "services-terms-and-services___de",
    path: "/de/services/terms-and-services",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/terms-and-services.vue").then(m => m.default || m)
  },
  {
    name: "services-terms-and-services___en",
    path: "/en/services/terms-and-services",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/terms-and-services.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___de___default",
    path: "/sustainability",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___de",
    path: "/de/sustainability",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "sustainability___en",
    path: "/en/sustainability",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: "urban-brand-space___de___default",
    path: "/urban-brand-space",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/urban-brand-space.vue").then(m => m.default || m)
  },
  {
    name: "urban-brand-space___de",
    path: "/de/urban-brand-space",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/urban-brand-space.vue").then(m => m.default || m)
  },
  {
    name: "urban-brand-space___en",
    path: "/en/urban-brand-space",
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/urban-brand-space.vue").then(m => m.default || m)
  }
]